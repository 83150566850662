/* Document level adjustments */
#root {
  font-size: calc(18px+0.1vw);
}

// change the theme
$theme-colors: (
  'primary': #70009c,
  'secondary': #ff8c2e,
);

$body-bg: #fff;
$body-color: #111;

// Import Bootstrap and its default variables
@import 'node_modules/bootstrap/scss/bootstrap';

// Ant Design
@import '~antd/dist/antd.compact.css';

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&family=Roboto:wght@400;500&family=Cabin:wght@689;700&family=Arimo:wght@600&display=swap');

.ant-menu-item .logo {
  width: 107.11px;
  height: 32px;
}

.ant-btn-primary {
  background-color: #70009c;
  color: #fff;
}

.ant-btn-clear {
  border-color: #70009c;
  color: #70009c;
}

.ant-btn-clear:hover {
  background-color: #550076;
  border-color: #4b0069;
  color: #fff;
}

.site-layout .site-layout-background {
  background: #fff;
}

@media (max-width: 900px) {
  #root {
    font-size: calc(15px+0.5vw);
  }
}

@media (max-width: 400px) {
  #root {
    font-size: calc(12px+0.1vw);
  }
}
